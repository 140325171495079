<template>
    <Contact/>
</template>

<script>
import Contact from '../components/Contact/index'
export default {
	components: {Contact  },
    metaInfo: {
        title: 'Contact us',
        titleTemplate: '%s | Roftr is right choise for quality Website development in Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'Any clarification related to Website and software development, Domain registration, Business Emails, call us now.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link:[
            { rel:'alternate', href:'www.roftr.com', hreflang:'en-in'}
        ]
    }

}
</script>

<style>

</style>