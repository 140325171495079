<template>
      <!--====== APPIE SPONSER PART START  ======-->
    
    <section class="appie-sponser-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">{{title}} <br> {{more_title}}</h3>
                        <p>{{description}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-sponser-box d-flex justify-content-center">
                        <div class="sponser-item">
                           <a href="https://www.shreedevitextile.com" target="_blank" title="Shree Devi Textile" rel="nofollow" >
                            <img src="@/assets/images/clients/shree-devi-textile.png" alt="shree devi textile" />
                           </a>
                        </div>
                        <div class="sponser-item">
                            <a href="https://www.karurcinemas.com" target="_blank" title="Karur Cinemas" rel="nofollow" >
                                <img src="@/assets/images/clients/karur-cinemas.png" alt="karur cinemas" />
                            </a>
                        </div>
                        <div class="sponser-item">
                            <a href="https://www.senthilkumarantheatres.com" target="_blank" title="Senthil Kumaran Theatres" rel="nofollow" >
                                <img src="@/assets/images/clients/senthil-kumaran-theatres.png" alt="senthil kumaran theatres" />
                            </a>
                        </div>
                        <div class="sponser-item">
                            <a href="https://www.aswati.in" target="_blank" title="Aswati Online" rel="nofollow" >
                                <img src="@/assets/images/clients/aswati.png" alt="aswati" />
                            </a>
                        </div>
                        <div class="sponser-item">
                            <a href="https://www.urudifinance.com" target="_blank" title="Urudi Finance" rel="nofollow" >
                                <img src="@/assets/images/clients/urudi-finance.png" alt="Urudi Finance" />
                            </a>
                        </div>
                        <div class="sponser-item">
                            <a href="https://www.gokapital.com" target="_blank" title="GoKapital" rel="nofollow" >
                                <img src="@/assets/images/clients/gokapital.png" alt="gokapital" />
                            </a>
                        </div>
                    </div>
                    <div class="appie-sponser-box item-2 d-flex justify-content-center">
                        <div class="sponser-item">
                            <a href="https://www.msvgranites.com" target="_blank" title="Msv Granites" rel="nofollow" >
                                <img src="@/assets/images/clients/msv-granites.png" alt="Msv Granites" />
                            </a>
                        </div>
                        <div class="sponser-item">
                            <a href="https://www.mcarsindia.com" target="_blank" title="Mannadiar Cars" rel="nofollow" >
                                <img src="@/assets/images/clients/mannadia-cars.png" alt="Mannadiar Cars" />
                            </a>
                        </div>
                        <div class="sponser-item">
                            <a href="https://www.newsbricks.com" target="_blank" title="News Bricks" rel="nofollow" >
                                <img src="@/assets/images/clients/newsbricks.png" alt="newsbricks" />
                            </a>
                        </div>
                        <div class="sponser-item">
                            <a href="https://www.krvmeridian.com" target="_blank" title="KRV Meridian" rel="nofollow" >
                                <img src="@/assets/images/clients/krv-meridian.png" alt="KRV Meridian" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sponser-shape">
            <img src="@/assets/images/sponser-shape.png" alt="sponser-shape" />
        </div>
    </section>
    
    <!--====== APPIE SPONSER PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        more_title:{
            type:String,
        },
        description:{
            type:String,
        }
    }

}
</script>

<style>

</style>