<template>
  <div>
       <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <!--====== OFFCANVAS MENU PART ENDS ======-->
   
    <!--====== PART START ======-->
    
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    
    <!--====== PART ENDS ======-->

    <!--====== APPIE PAGE TITLE PART START ======-->
    
    <!-- reference by news -->
    <title-area/>
    
    <!--====== APPIE PAGE TITLE PART ENDS ======-->

        <!-- Contact Start -->
        <contact-inputs/>
        <!-- Contact End -->

        <!-- Gamps Start -->
        <div class="bisylms-map">            
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15664.86907573935!2d77.006838!3d11.022319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc8dff980e29c20c3!2sRoftr!5e0!3m2!1sen!2sin!4v1637044320844!5m2!1sen!2sin" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <!-- Gamps Start -->

    <!--====== APPIE FOOTER PART START ======-->
    
    <footer-home-two/>
    
    <!--====== APPIE FOOTER PART ENDS ======-->


    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->

  </div>
</template>

<script>
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
import TitleArea from './TitleArea.vue'
import ContactInputs from './ContactInputs.vue'
import HeaderProduct from '../Header/HeaderProduct.vue'
export default {
	components: { SidebarHomeTwo, HeaderProduct, TitleArea, ContactInputs, FooterHomeTwo },
        data(){
        return{
            sidebar:false,
              navs:[
                {
                    name:'home',
                    path:'/'
                },
                {
                    name:'About us',
                    path:'/about-us'
                },
                {
                    name:'Zoho',
                    path:'/zoho'            
                },
                {
                    name:'Industries',
                    childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },*/
                    { 
                        name:'Hospitals and Clinics',
                        path:''
                    },
                    ],
                },
                // {
                //     name:'Services',
                //     path:'/services'
                // },
                // {
                //     name:'Pricing',
                //     path:'/pricing'
                // },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ]
        }
    },
     mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
         toggleSidebar(){
           this.sidebar = !this.sidebar
        },  
    }

}
</script>

<style>

</style>