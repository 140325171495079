<template>
      <section class="appie-services-2-area appie-services-8-area pt-90 pb-55" id="service">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-lg-6 col-md-8">
                    <div class="appie-section-title">
                        <h3 class="appie-title">Solution for every Theatre Owners.</h3>
                        <p>The application invested by theatre owner for theatre owners.</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <div class="icon">
                                    <i class="fal fa-tv"></i>
                                </div>
                                <h4 class="title">Customised DCR Report</h4>
                                <p>DCR, GST report with serial number. Daily and Monthly report based on Online and counter sales.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                                <div class="icon">
                                    <i class="fal fa-code"></i>
                                </div>
                                <h4 class="title">Single Window Ticketing</h4>
                                <p>Single app with centralised data center to book ticket at Box Office and also for the Online customers.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                                <div class="icon">
                                    <i class="fal fa-user-friends"></i>
                                </div>
                                <h4 class="title">KIOSK Ticketing</h4>
                                <p>Our KIOSK ticketing applications makes the booking simple with just single click or with Payment ID.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <div class="icon">
                                    <i class="fal fa-mobile"></i>
                                </div>
                                <h4 class="title">Advanced Payment Gateway</h4>
                                <p>With plenty of payments options like Credit/Debit Cards, Internet Baking, UPI, Google Pay, PayTM, PhonePe and more...</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="service-thumb">
                        <img src="@/assets/images/service-thumb-1.png" alt="Movie Theatre Ticket Booking"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>