<template>
    <Careersform/>
</template>

<script>
import Careersform from '../components/CareersForm/index'
export default {
	components: {Careersform  },
    metaInfo: {
        title: 'Careers',
        titleTemplate: '%s | Roftr Future Technologies Website development company, Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'We care your Website Design, Web Application development, Search Engine Optimization, Mobile Application Development, SMS Gateway, Internet Payment Gateway.....' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link: [
            { rel: 'alternate', href: 'www.roftr.com', hreflang: 'en-in'}
        ]
    }

}
</script>

<style>
 
</style>