<template>
  <div>
      
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <hero-home-three title="Creative way to Showcase your app" description="Jolly good excuse my french boot super my good sir cup of" more_description="char richard about chinwag." videoUrl="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1" />
    <features-static-website/>
    <funfact-home-three/>
    <modules-static-website/>
    <show-case-home-three/>
    <sponser-home-two title="Our Clients" more_title="" description="Best Investors for the growth of our Product."/>
    <get-started/>
    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    

    <div class="back-to-top back-to-top-3">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import blogImgFour from '@/assets/images/blog-4.jpg'
import blogImgFive from '@/assets/images/blog-5.jpg'
import blogImgSix from '@/assets/images/blog-6.jpg'
import blogImgSeven from '@/assets/images/blog-7.jpg'

import SidebarHomeTwo from '../../Sidebar/SidebarHomeTwo.vue'
import ModulesStaticWebsite from './ModulesStaticWebsite.vue'

import GetStarted from '../../Footer/GetStarted.vue'
import SponserHomeTwo from '../../Footer/SponserHomeTwo.vue'
import FooterHomeTwo from '../../Footer/FooterHomeTwo.vue'
import FunfactHomeThree from './FunfactHomeThree.vue'

import HeaderProduct from '../../Header/HeaderProduct.vue'
import HeroHomeThree from './HeroHomeThree.vue'

import FeaturesStaticWebsite from './FeaturesStaticWebsite.vue'
import ShowCaseHomeThree from './ShowCaseHomeThree.vue'
export default {
	components: { HeaderProduct, HeroHomeThree, GetStarted, FooterHomeTwo, FunfactHomeThree, SponserHomeTwo, FeaturesStaticWebsite, ModulesStaticWebsite, ShowCaseHomeThree, SidebarHomeTwo },
        data(){
        return{
            sidebar:false,
            navs:[
                {
                    name:'home',
                    path:'/'
                },
                {
                    name:'About us',
                    path:'/about-us'
                },
                {
                    name:'Zoho',
                    path:'/zoho'            
                },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{
                            name:'Static Website',
                            path:'/product/static-website'
                        },
                        { 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'Services',
                //     path:'/services'
                // },
                // {
                //     name:'Pricing',
                //     path:'/pricing'
                // },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
            blogImgFour:blogImgFour,
            blogImgFive:blogImgFive,
            blogImgSix:blogImgSix,
            blogImgSeven:blogImgSeven,
            blogs:[
                {
                    img:blogImgFour,
                    title:'How to Improve Your App Store Position',
                    date:'July 14, 2022',
                    comment_date:'July 14, 2022'
                },
                {
                    img:blogImgFive,
                    title:'Introducing New App Design for our iOS App',
                    date:'July 14, 2022',
                    comment_date:'July 14, 2022'
                },
                {
                    img:blogImgSix,
                    title:'Engineering job is Becoming More interesting.',
                    date:'July 14, 2022',
                    comment_date:'July 14, 2022'
                },
                {
                    img:blogImgSeven,
                    title:'20 Myths About Mobile Applications',
                    date:'July 14, 2022',
                    comment_date:'July 14, 2022'
                },
            ]
        }
    },
   mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },   
    }

}
</script>

<style>

</style>