<template>
  <!--====== APPIE SERVICES PART START ======-->
    
    <section class="appie-traffic-area pt-50 pb-50" id="service">
        <div class="container">
            <div class="row ptb-100">
                <div class="py-50" id="payment-response">
                    {{ response }}
                </div>
                <div class="col-sm-3"></div>
                <div class="col-sm-6">
                    <div class="appie-about-8-box pb-5">
                        <h1 class="text-danger text-center">Payment Failed</h1>
                        <div class="py-3 text-center">
                            <i class="far fa-times-circle text-danger" style="font-size: 4em;"></i>
                        </div>   
                        <div class="text-center">
                            <router-link to="/" class="main-btn">
                                Back to Home <i class="fal fa-arrow-right"></i>
                            </router-link>
                        </div>  
                    </div>
                </div>
                <div class="col-sm-3"></div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>

export default {
    data: function() {
		return {
            response : ""
		};
	},
    mounted: function() {        
        this.callPaymentResponse();	
	},
    methods: {
        callPaymentResponse: function(){
            this.response = localStorage.getItem('payment_response');
        }
    }
}
</script>
