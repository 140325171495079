<template>
  <!--====== APPIE TRAFFIC PART START ======-->
    
    <section class="appie-traffic-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-traffic-title">
                        <span>{{small_title}}</span>
                        <h3 class="title">{{big_title}}</h3>
                        <p>{{description}}</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4" v-for="(feature,index) in features" :key="index">
                            <div class="appie-traffic-service mb-30" :class="[feature.bg && feature.bg !== '' ? feature.bg :'']">
                                <div class="icon">
                                    <i class="fal fa-check"></i>
                                </div>
                                <h5 class="title">{{feature.title}}</h5>
                                <p>{{feature.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE TRAFFIC PART ENDS ======-->
</template>

<script>
export default {
    props:{
        traffic_thumbnail:{
            type:String,
        },
        small_title:{
            type:String,
        },
        big_title:{
            type:String
        },
        description:{
            type:String,
        },
        features:{
            type:Array,
            default:() => {
                return []
            }
        }
    }

}
</script>

<style>

</style>