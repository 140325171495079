<template>
  <section class="appie-services-2-area pt-90 pb-55" id="service">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-lg-6 col-md-8">
                    <div class="appie-section-title">
                        <h3 class="appie-title">Services Offered in Zoho</h3>
                        <p>Roftr offers a wide range of services to cater to the diverse needs of their clients. These services include:</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-tv"></i>
                        </div>
                        <h4 class="title">Zoho Implementation:</h4>
                        <p>The company assists businesses in the seamless integration of Zoho software into their existing infrastructure. This includes setting up Zoho CRM, Zoho Books, Zoho Analytics, Zoho Projects, and other Zoho applications tailored to the specific requirements of each client.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-code"></i>
                        </div>
                        <h4 class="title">Customization and Development:</h4>
                        <p>Roftr has a team of experienced developers who can customize Zoho applications to suit unique business processes. They create custom modules, workflows, and reports, ensuring that Zoho software aligns perfectly with the client's operational needs.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fal fa-user-friends"></i>
                        </div>
                        <h4 class="title">Training and Onboarding:</h4>
                        <p>Roftr offers training programs to help clients and their employees become proficient in using Zoho software effectively. This ensures that businesses can maximize the benefits of Zoho applications from day one.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-mobile"></i>
                        </div>
                        <h4 class="title">Data Migration:</h4>
                        <p>The company assists clients in transferring their data from existing systems to Zoho software. This is a critical step in the transition process, and Roftr ensures that data is migrated accurately and securely.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-retweet"></i>
                        </div>
                        <h4 class="title">Integration Services:</h4>
                        <p>Roftr can integrate Zoho applications with other third-party software, creating a seamless workflow and eliminating data silos. This integration enhances the efficiency and productivity of businesses.</p>
                    </div>
                </div>
             
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>