<template>
    <about-us/>
</template>

<script>
import AboutUs from "../components/AboutUs/index"
export default {
    components: {
        AboutUs
    },
    metaInfo: {
        title: 'About us',
        titleTemplate: '%s | Roftr Software and Website development company, Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },    
        meta: [
            { name: 'Description', content: 'Roftr focus on software research and development and it is the primary task any kind of products to make it success for all clients.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link: [
            { rel: 'alternate', href: 'www.roftr.com', hreflang: 'en-in'}
        ],
    }
}
</script>

<style>

</style>