<template>
  <error-com/>
</template>

<script>
import ErrorCom from '../components/ErrorCom.vue'
export default {
	components: {ErrorCom },
    metaInfo: {
        title: '404 Page',
        titleTemplate: '%s | Page not found',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: '404 Error, the page your looking for is removed or might never existed. Browse other pages for your needs.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link:[
            { rel:'alternate', href:'www.roftr.com', hreflang:'en-in'}
        ]
    }
}
</script>

<style>

</style>