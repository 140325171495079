<template>
          <!-- Contact Start -->
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="contact--info-area">
                            <h3>Get in touch</h3>
                            <p>
                                Looking for help? Fill the form and start a new adventure.
                            </p>
                            <div class="single-info">
                                <h5>Headquaters.</h5>
                                <p>
                                    <i class="fal fa-home"></i>
                                    
                                    Roftr Clouds LLP <br />A-44, Renga Complex, HUDCO Colony, <br/>Near PSG Hospital, Peelamedu, <br/>Coimbatore, Tamil Nadu - 641004.
                                </p>
                            </div>
                            <!-- <div class="single-info">
                                <h5>GOC</h5>
                                <p>
                                    <i class="fal fa-home"></i>
                                    C4Cloud - 4th Floor, 648/A,<br /> Binnamangala 1st Stage<br /> Indiranagar, Bengaluru, <br />Karnataka, 560038.

                                </p>
                            </div> -->
                            <div>
                                <h5>Tele Communication.</h5><br/>
                                <p>
                                   
                                    <a href="tel:+919790403333" class="btn-home" title="Contact Roftr"><i class="fal fa-phone"></i>  Clarification? Call Us</a>
                                    
                                    <br/><br/>
                                    
                                </p>
                            </div>
                            
                            <div class="ab-social">
                                <h5>Follow Us.</h5>
                                <a class="fac" href="https://www.facebook.com/roftrindia/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                <a class="twi" href="https://twitter.com/roftr" target="_blank"><i class="fab fa-twitter"></i></a>
                                <!--<a class="you" href="#"><i class="fab fa-youtube"></i></a>
                                <a class="lin" href="#"><i class="fab fa-linkedin-in"></i></a>-->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <!-- <div>
                            <ul v-if="posts && posts.length">
                            <li v-for="post of posts" v-bind:key="post">
                                <p><strong>{{post.title}}</strong></p>
                                <p>{{post.body}}</p>
                            </li>
                            </ul>

                            <ul v-if="errors && errors.length">
                            <li v-for="error of errors" v-bind:key="error">
                                {{error.message}}
                            </li>
                            </ul>
                        </div> -->
                        <div class="contact-form">
                            <h4>Let’s Connect</h4>
                            <p>Like to ask us something? Use the below enquiry form , we'll get back to you as early as possible.</p>
                            <form action="#" method="post" class="" autocomplete="off">
                                <div class="row">
                                    <div class="col-md-12">
                                        <input type="text" name="name" id="name" v-model="contact.name" placeholder="Name">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="email" name="email" id="email" v-model="contact.email" placeholder="Email Address">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" name="phone" id="phone" v-model="contact.phone" placeholder="Phone Number">
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" name="subject" id="subject" v-model="contact.subject" placeholder="Subject">
                                    </div>
                                    <div class="col-md-12">
                                        <textarea name="message" id="message" v-model="contact.message" placeholder="How can we help?"></textarea>
                                    </div>
                                    <div class="col-md-6" id="error-alert">
                                        
                                    </div>
                                    <div class="col-md-6">
                                        <input type="submit" name="submit" value="Send Message" @click.prevent="onSend()" style="float:right">
                                    </div>
                                </div>                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact End -->
</template>

<script>
import axios from 'axios';

export default {
    data(){
		return{
			contact:{
				name:null,
				email:null,
                phone:null,
                subject:null,
                message:null
			}			
		}     
	},
    methods:{
		onSend(){      
            var obj = new Object();
            obj['name']     = this.contact.name;
            obj['email']    = this.contact.email;
            obj['phone']    = this.contact.phone;
            obj['subject']  = this.contact.subject;
            obj['message']  = this.contact.message;
            
            var myJSON = JSON.stringify(obj);
            document.getElementById("error-alert").innerHTML = "<div class='alert-msg infomsg'><div class='text-primary'>Processing...</div></div>";
			axios.post("https://adm.roftr.com/api/contact","data="+myJSON,{ useCredentails: true })
			.then((response)=>{			 
                var data = response.data;          
                if(data == "Success"){
                    document.getElementById("error-alert").innerHTML = "<div class='alert-msg successmsg'><div class='text-success'>Message Sent Successfully!</div></div>";                
                    setTimeout(function(){ location.reload(); },2000);
                }else{
                    document.getElementById("error-alert").innerHTML = "<div class='alert-msg errormsg'><div class='text-danger'>Message Not Sent!</div></div>";
                }          
			})
			.catch((error)=>{
				console.log(error);
			}) 
		}
	}
}
</script>

<style>

</style>