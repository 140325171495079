<template>
    <PaymentFailure/>
</template>

<script>
import PaymentFailure from '../components/PaymentFailure/index'
export default {
	components: {PaymentFailure },
}
</script>

<style>

</style>