<template>
  <!--====== APPIE FAQ PART START ======-->
    
    <section class="appie-faq-area pb-95">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">{{title}}</h3>
                        <p>{{description}}</p>
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion" @click.prevent="OpenQuestion(1)" :class="[showQuestion === 1?'active':'']">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Need Software only for Box Office Sales?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 1?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Yes, we offer Box Office ticket sales software without online ticket booking and We charge basic setup fees as one-time cost.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion  " :class="[showQuestion === 2?'active':'']" @click.prevent="OpenQuestion(2)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Duration to setup Online/Box Office for my Theatre?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 2?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>We can have Counter sales software within 3 days based on the Ticket Class and seating arrangements layout provided by you. If we complete documents for Online payment gateway, we can do LIVE hardly in 7 working days. </p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion" :class="[showQuestion === 5?'active':'']" @click.prevent="OpenQuestion(5)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Online Customer Refunds and Cancellation?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 5?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Our system supports single click refund process to customers and within 4 working days, customer gets his money to respective payment mode. Our system has ticket Cancellation module with cancellation fees detected to customers as optional.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                           
                        </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion " :class="[showQuestion === 3?'active':'']" @click.prevent="OpenQuestion(3)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Which payment gateway used &amp; Payment methods?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 3?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Our system comes with <a href="https://worldline.com/en/home/solutions/online-payments.html" title="Worldline payment gateway" target="_blank" rel="nofollow" >Worldline payment gateway</a> and also We can integrate any payment gateway which ever provides lowest Transaction commission for each transaction made by online customers. Payments methods are Credit/Debit Cards, Internet Banking, UPI Payments, Google Pay, PhonePe, PayTM and wallets.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion" :class="[showQuestion ===4?'active':'']" @click.prevent="OpenQuestion(4)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>How about online payment risk?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 4?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Each transactions passed via encrypted mode and no data's stored on website. If any customer has payment issues, our backend team will get alert instantly to fix the issue or our system will allocate seats within 15 mins time interval if same customer not booked seats in different transaction.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            
                             <div class="accrodion" :class="[showQuestion === 6?'active':'']" @click.prevent="OpenQuestion(6)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>How about database backup?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 6?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Our system runs database backup twice a day and you can setup backup time interval as you like. Database will be encrypted and stored in the cloud server. </p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="col-lg-12">
                    <div class="faq-text text-center pt-40">
                        <p>Can't find an answer? <a href="mailto:support@roftr.com">Email us</a></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE FAQ PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        }
    },
   data(){
       return {
           showQuestion:1,
       }
   },
    methods: {
        OpenQuestion(value){
            this.showQuestion = value
        }
    }

}
</script>

<style>

</style>