<template>
  <!--====== APPIE FAQ PART START ======-->
    
    <section class="appie-faq-area pb-95">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">Why Choose Roftr as a Zoho Partner?</h3>
                        <!-- <p>{{description}}</p> -->
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion" @click.prevent="OpenQuestion(1)" :class="[showQuestion === 1?'active':'']">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Expertise</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 1?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Roftr boasts a team of highly skilled professionals who are well-versed in Zoho's software suite. Their expertise ensures that clients receive tailored solutions that meet their unique needs.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion  " :class="[showQuestion === 2?'active':'']" @click.prevent="OpenQuestion(2)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Customer-Centric Approach:</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 2?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>The company places a strong emphasis on understanding the client's business objectives and tailoring Zoho solutions accordingly. They prioritize customer satisfaction and long-term success.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion" :class="[showQuestion === 5?'active':'']" @click.prevent="OpenQuestion(5)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Proven Track Record:</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 5?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>With over a decade of experience, Roftr has a proven track record of successfully implementing Zoho solutions for a wide range of businesses, from startups to established enterprises.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                           
                        </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion " :class="[showQuestion === 3?'active':'']" @click.prevent="OpenQuestion(3)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Ongoing Support:</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 3?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Roftr doesn't just stop at implementation; they provide ongoing support and maintenance to ensure that Zoho applications continue to perform optimally as the client's business evolves.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion" :class="[showQuestion ===4?'active':'']" @click.prevent="OpenQuestion(4)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Cost-Effective Solutions:</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 4?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Roftr offers cost-effective Zoho solutions that provide excellent value for money, making Zoho software accessible to businesses of all sizes.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    </section>
    
    <!--====== APPIE FAQ PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        }
    },
   data(){
       return {
           showQuestion:1,
       }
   },
    methods: {
        OpenQuestion(value){
            this.showQuestion = value
        }
    }

}
</script>

<style>

</style>