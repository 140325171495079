<template>
    <Disclaimer/>
</template>

<script>
import Disclaimer from '../components/PolicyPages/Disclaimer.vue'
export default {
	components: {Disclaimer },
    metaInfo: {
        title: 'Disclaimer',
        titleTemplate: '%s | Roftr Disclaimer Policy, Website development company, Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'All information on the Site and our mobile application is provided in good faith, however we make no representation' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link:[
            { rel:'alternate', href:'www.roftr.com', hreflang:'en-in'}
        ]
    }
}
</script>

<style>

</style>