<template>
    <Staticwebsite/>
</template>

<script>
import Staticwebsite from '../components/Products/Static-website/index'
export default {
	components: {Staticwebsite },

}
</script>

<style>

</style>