<template>
      <!--====== APPIE PRICING 2 PART START ======-->
    
    <section class="appie-pricing-2-area pt-100 pb-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">{{title}}</h3>
                        <p>{{description}}</p>
                        <div class="appie-pricing-tab-btn">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation"  @click.prevent="change_plan">
                                    <a class="nav-link" :class="[switchPlan?'active':'']" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Monthly</a>
                                </li>
                                <li class="nav-item" role="presentation" :class="[switchPlan?'on':'off']"  @click.prevent="change_plan">
                                    <a class="nav-link" :class="[switchPlan === false?'active':'']" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Yearly</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade" :class="[switchPlan?'active show':'']" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 wow animated fadeInLeft">
                                        <div class="pricig-heading">
                                            <h6>Silver</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>6500</span><p>/month</p></div>
                                            <p>Suitable for individuals. Get your 14 day free trial</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> Personal Loan</li>
                                                <li><i class="fal fa-check"></i> Business Loan</li>
                                                <li><i class="fal fa-check"></i> SMS Alerts</li>
                                                <li><i class="fal fa-check"></i> Loan Application</li>
                                                <li><i class="fal fa-check"></i> Loan Approval</li>
                                                <li><i class="fal fa-check"></i> EMI collection</li>
                                                <li><i class="fal fa-check"></i> Ledgers</li>
                                                <li><i class="fal fa-check"></i> Reports</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="javascript:;" @click="verifyPayment('6500')">Pay Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 active wow animated fadeInUp">
                                        <div class="pricig-heading">
                                            <h6>Gold</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>8500</span><p>/month</p></div>
                                            <p>Get your 14 day free trial</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> includes all Silver modules</li>
                                                <li><i class="fal fa-check"></i> Micro Finance Loan</li>
                                                <li><i class="fal fa-check"></i> MSME Loan</li>
                                                <li><i class="fal fa-check"></i> WhatsApp Alerts</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="javascript:;" @click="verifyPayment('8500')">Pay Now</a>
                                            </div>
                                            <div class="pricing-rebon">
                                                <span>Most Popular</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 item-2 wow animated fadeInRight">
                                        <div class="pricig-heading">
                                            <h6>Platinum</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>12000</span><p>/month</p></div>
                                            <p>Get your 14 day free trial</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> Includes all Gold modules</li>
                                                <li><i class="fal fa-check"></i> Online Payment Gateway</li>
                                                <li><i class="fal fa-check"></i> ECS integration</li>
                                                <li><i class="fal fa-check"></i> NACH Payment</li>
                                                <li><i class="fal fa-check"></i> Aadhar Validation</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="javascript:;" @click="verifyPayment('12000')">Pay Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="[switchPlan=== false?'active show':'']"  id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 animated fadeInLeft">
                                        <div class="pricig-heading">
                                            <h6>Silver</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>5000</span><p>/Month</p><p class="">, billed Yearly</p></div>
                                            <p>Suitable for individuals. Get your 14 day free trial</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> Personal Loan</li>
                                                <li><i class="fal fa-check"></i> Business Loan</li>
                                                <li><i class="fal fa-check"></i> SMS Alerts</li>
                                                <li><i class="fal fa-check"></i> Loan Application</li>
                                                <li><i class="fal fa-check"></i> Loan Approval</li>
                                                <li><i class="fal fa-check"></i> EMI collection</li>
                                                <li><i class="fal fa-check"></i> Ledgers</li>
                                                <li><i class="fal fa-check"></i> Reports</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="javascript:;" @click="verifyPayment('5000')">Pay Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 active animated fadeInUp">
                                        <div class="pricig-heading">
                                            <h6>Gold</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>6500</span><p>/Month</p><p class="">, billed Yearly</p></div>
                                            <p>Get your 14 day free trial</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> includes all Silver modules</li>
                                                <li><i class="fal fa-check"></i> Micro Finance Loan</li>
                                                <li><i class="fal fa-check"></i> MSME Loan</li>
                                                <li><i class="fal fa-check"></i> WhatsApp Alerts</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="javascript:;" @click="verifyPayment('6500')">Pay Now</a>
                                            </div>
                                            <div class="pricing-rebon">
                                                <span>Most Popular</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 item-2 animated fadeInRight">
                                        <div class="pricig-heading">
                                            <h6>Platinum</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>10000</span><p>/Month</p><p class="">, billed Yearly</p></div>
                                            <p>Get your 14 day free trial</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> Includes all Gold modules</li>
                                                <li><i class="fal fa-check"></i> Online Payment Gateway</li>
                                                <li><i class="fal fa-check"></i> ECS integration</li>
                                                <li><i class="fal fa-check"></i> NACH Payment</li>
                                                <li><i class="fal fa-check"></i> Aadhar Validation</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="javascript:;" @click="verifyPayment('10000')">Pay Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE PRICING 2 PART ENDS ======-->
</template>

<script>
import axios from 'axios';
export default {
    props:{
        title: {
            type: String,
        },
        description: {
            type: String,
        }
    },
     data(){
        return{
            switchPlan:true
        }
    },
    async mounted() { 	
        const script1 = document.createElement("script");
              script1.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js";
              script1.addEventListener("load",this.setLoaded);
              document.body.appendChild(script1);
        
        const script2 = document.createElement("script");
              script2.src = "https://www.paynimo.com/paynimocheckout/server/lib/checkout.js"; 
              script2.addEventListener("load",this.setLoaded);
              document.body.appendChild(script2);
    },
    methods:{
         change_plan(){
            this.switchPlan = !this.switchPlan
        },
        verifyPayment(e){         
            console.log(e);
            if(!confirm("Are you sure you want to Pay?")){                    
                return false;
            } 
            let formData        = new FormData();
            axios.post("https://adm.roftr.com/api/connect-payment/payment-request",formData,{  headers: { 'Content-Type': 'multipart/form-data' } }) 
            .then(response => {        
                if(response.data.status == "success"){ 
                    var result = response.data.response_data;
                    console.log(response.data.response_data);
                    var configJson = 
                    {
                        'tarCall': false,
                        'features': {
                            'showPGResponseMsg': true,
                            'enableNewWindowFlow': true,   //for hybrid applications please disable this by passing false
                            'enableAbortResponse': true,
                            'enableExpressPay': true,  //if unique customer identifier is passed then save card functionality for end  end customer
                            'enableInstrumentDeRegistration': true,  //if unique customer identifier is passed then option to delete saved card by end customer
                            'enableMerTxnDetails': true,
                            'siDetailsAtMerchantEnd': false,
                            'enableSI': false,
                            'hideSIDetails': false,
                            'enableDebitDay': false,
                            'expandSIDetails': false,
                            'enableTxnForNonSICards': false,
                            'showSIConfirmation': false,
                            'showSIResponseMsg': false,
                        },                            
                        'consumerData': {
                            'deviceId': 'WEBSH2',
                            //possible values 'WEBSH1', 'WEBSH2' and 'WEBMD5'
                            //'debitDay':'10',
                            'token': result.hash,
                            'returnUrl': result.data[12],
                            //'redirectOnClose': 'https://www.tekprocess.co.in/MerchantIntegrationClient/MerchantResponsePage.jsp',
                            'responseHandler': this.handleResponse,
                            'paymentMode': 'all',                                
                            //"paymentModeOrder": ["wallets","cards","netBanking","imps","cashCards","UPI","MVISA","debitPin","NEFTRTGS","emiBanks"],  
                            'checkoutElement': '',
                            'merchantLogoUrl': 'https://www.roftr.com/logo-color.png',  //provided merchant logo will be displayed
                            'merchantId': result.data[0],
                            'currency': result.data[15],
                            'consumerId': result.data[8],  //Your unique consumer identifier to register a eMandate/eNACH
                            'consumerMobileNo': result.data[9],
                            'consumerEmailId': result.data[10],
                            'txnId': result.data[1],   //Unique merchant transaction ID
                            'items': [{
                                'itemId': result.data[14],
                                'amount': result.data[2],
                                'comAmt': '0'
                            }],
                            'cartDescription': '}{custname:'+result.data[13],
                            'merRefDetails': [
                                {"name": "Txn. Ref. ID", "value": result.data[1]}
                            ],
                            'customStyle': {
                                'PRIMARY_COLOR_CODE': '#3977b7',   //merchant primary color code
                                'SECONDARY_COLOR_CODE': '#FFFFFF',   //provide merchant's suitable color code
                                'BUTTON_COLOR_CODE_1': '#1969bb',   //merchant's button background color code
                                'BUTTON_COLOR_CODE_2': '#FFFFFF'   //provide merchant's suitable color code for button text
                            },
                            'accountNo': result.data[11],    //Pass this if accountNo is captured at merchant side for eMandate/eNACH
                            'accountHolderName': result.data[16],  //Pass this if accountHolderName is captured at merchant side for ICICI eMandate & eNACH registration this is mandatory field, if not passed from merchant Customer need to enter in Checkout UI.
                            'ifscCode': result.data[17],        //Pass this if ifscCode is captured at merchant side.
                            'accountType': result.data[18],  //Required for eNACH registration this is mandatory field
                            'debitStartDate': result.data[3],
                            'debitEndDate': result.data[4],
                            'maxAmount': result.data[5],
                            'amountType': result.data[6],
                            'frequency': result.data[7]  //  Available options DAIL, WEEK, MNTH, QURT, MIAN, YEAR, BIMN and ADHO
                        }
                    };                        
                    // console.log(configJson); 
                    $.pnCheckout(configJson);
                    if(configJson.features.enableNewWindowFlow){
                        pnCheckoutShared.openNewWindow();
                    }
                }                
            })
            .catch(function (error) {
                console.log(error);
            });
        },        
        handleResponse(res){
            if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0300') {
            // success block
            } else if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0398') {
            // initiated block
            } else {
            // error block
            }   
        }
    }

}
</script>

<style>

</style>