<template>
    <PaymentSuccess/>
</template>

<script>
import PaymentSuccess from '../components/PaymentSuccess/index'
export default {
	components: {PaymentSuccess },
}
</script>

<style>

</style>