<template>
    <Financialservices/>
</template>

<script>
import Financialservices from '../components/Products/Financial-services/index'
export default {
	components: {Financialservices },
    metaInfo: {
        title: 'Microfinance',
        titleTemplate: '%s | Best Loan Software provider with cloud service | Roftr, Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'Best financial software for Microfinance Loans, Personal Loans, MSME Loans and Business Loans with best security features.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Finance'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
            { property: 'og:title', content: 'Financial Service Application, Microfinance Loan Software, Personal Loan Software'},
            { property: 'og:type', content: 'website'},
            { property: 'og:description', content: 'Best financial software for Microfinance Loans, Personal Loans, MSME Loans and Business Loans with best security features.'},
            { property: 'og:url', content: 'https://www.roftr.com/product/financial-services/'},
            { property: 'og:image', content: 'https://www.roftr.com/assets/images/meta-images/financial-service-image.jpg'},
            { name: 'twitter:card', content: 'summary_large_image'},
            { name: 'twitter:title', content: 'Software for Microfinance Loan, Personal Loan, MSME and Business'},
            { property: 'twitter:domain', content: 'roftr.com'},
            { property: 'twitter:url', content: 'https://www.roftr.com/product/financial-services/'},
            { name: 'twitter:title', content: 'Software for Microfinance Loan, Personal Loan, MSME and Business'},
            { name: 'twitter:description', content: 'Best financial software for Microfinance Loans, Personal Loans, MSME Loans and Business Loans with best security features.'},
            { name: 'twitter:image', content: 'https://www.roftr.com/assets/images/meta-images/financial-service-image.jpg'},
        ],
        link: [
            { rel: 'alternate', href: 'www.roftr.com', hreflang: 'en-in'}
        ]
    }
}
</script>

<style>

</style>