<template>
  <!--====== APPIE FAQ PART START ======-->
    
    <section class="appie-blog-area pb-95">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center mt-5">
                        <h3 class="appie-title">{{title}}</h3>
                        <p>{{description}}</p>
                    </div>                    
                </div>
            </div>
            <div class="row" v-if="posts && posts.length">
                <div class="col-lg-12" v-for="post of posts" v-bind:key="post.id">
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion border border-2 border-primary" @click.prevent="OpenQuestion(post.id)" :class="[showQuestion === post.id?'active':'']">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title mt-2 mb-2">
                                        <h4>{{post.job_title}}</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === post.id?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <div class="row">
                                                <div class="col-lg-8" style="text-align:justify">
                                                    <pre style="white-space: pre-wrap;word-break: normal;font-family: 'Roboto';font-size: 14px;color: #505056;">{{br2nl(post.job_description)}}</pre>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="">
                                                        <p><span style="font-weight:600">Qualification : </span> {{post.qualification}}</p>
                                                        <p><span style="font-weight:600">Experience : </span> {{post.experience}}</p>
                                                        <p><span style="font-weight:600">No.of Vacant : </span> {{post.no_of_vacant}}</p>
                                                        <p><span style="font-weight:600">Gender : </span> {{post.gender}}</p>
                                                        <router-link :to="{ path: 'career/'+post.url_path, params: { job_id: post.id } }" class="btn btn-success text-white mt-2">Apply Now</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE FAQ PART ENDS ======-->
</template>

<script>
import axios from 'axios';
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        }
    },
    data(){
        return {
            showQuestion:1,
            posts: [],
        }
    },
    created() {
    axios.get("https://adm.roftr.com/api/careers/careers_list")
    .then(response => {
      // JSON responses are automatically parsed.
      this.posts = response.data 
        // console.log(this.$route.params.job_id)      
    })
    .catch(e => {
      this.errors.push(e)
    })
  },
    methods: {
        OpenQuestion(value){
            this.showQuestion = value
        },
        br2nl(value){
            return value.replace(/<br[^>]*>/gi, "\n"); 
        }
    }   
    
}
</script>

<style>

</style>