<template>
  <section class="appie-services-2-area pt-90 pb-55" id="service">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-lg-6 col-md-8">
                    <div class="appie-section-title">
                        <h3 class="appie-title">Online Shopping Website Features and Plans</h3>
                     <!-- <p>The app provides design and digital marketing. </p>  -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-tv"></i>
                        </div>
                        <h4 class="title">Online Payment Gateway.</h4>
                        <p>Best payment gateway, Transaction comission starts from 0.40% and with features of Credit/Debit Cards, Wallets, NetBanking and UPI Payments.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-code"></i>
                        </div>
                        <h4 class="title">Traffic Handling and Security</h4>
                        <p>Concurrent traffic handling depends on the server you choose and we support with Auto scaling and Load balancers, includes SSL secured traffic.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fal fa-user-friends"></i>
                        </div>
                        <h4 class="title">Yearly Renewal Cost</h4>
                        <p>From 2nd year onwards, yearly renewal of domain and cloud server is based on application size and configuration to handle traffic.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-mobile"></i>
                        </div>
                        <h4 class="title">Data Backup and Export</h4>
                        <p>Daily cloud backup with Export data to xml feeds. Import old website data or data from any source to our eCom app, goLive with Cloud server.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-retweet"></i>
                        </div>
                        <h4 class="title">Search Engine Optimization</h4>
                        <p>Every web application should be optimized according to the Search Engines, which helps Online visibility with Rich Snippets.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fal fa-bell"></i>
                        </div>
                        <h4 class="title">Maintenance &amp; Support</h4>
                        <p>We keep support for any bugs and updateas as long as needed with regular Data maintenance.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>