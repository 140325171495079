<template>
    <div>
  
      <!--====== OFFCANVAS MENU PART START ======-->
  
      <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
  
      <!--====== OFFCANVAS MENU PART ENDS ======-->
     
      <!--====== PART START ======-->
      
      
      
      <!--====== PART ENDS ======-->
  
      <!--====== APPIE HERO PART START ======-->
  
      <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
  
      <!--====== APPIE HERO PART ENDS ======-->
      <title-area/>
      <!--====== APPIE SERVICES PART START ======-->
      <!-- reference home one -->
      <!--====== APPIE SERVICES PART ENDS ======-->
  
      <!--====== APPIE FEATURES 2 PART START ======-->
      
      <!-- <traffic-home-one :traffic_thumbnail="traffic_thumb" small_title="Traffic" big_title="Financial Service Application." description="" :features="features"/> -->
      
      
      <Response />
      <!--====== APPIE FEATURES 2 PART ENDS ======-->
      
      <show-case-home-two/>
      <!--====== APPIE COUNTER PART ENDS ======-->
  
      <!-- <features/> -->
      
      <faq class="pb-95 pt-90" title="Frequently asked questions" description="The more you have questions in Financial services, we can bring the solutions."/>
      
      <testimonial/>
      <!--====== APPIE TESTIMONIAL PART START ======-->
      <!-- reference home one -->
      
      <!--====== APPIE TESTIMONIAL PART ENDS ======-->
        
      <!--====== APPIE PROJECT 3 PART START ======-->
      <!-- reference home three -->
      <get-started/>
      
      <!--====== APPIE PROJECT 3 PART ENDS ======-->
  
      <!--====== APPIE FOOTER PART START ======-->
      <!-- reference home three -->
      <footer-home-two class="appie-footer-area appie-footer-about-area"/>
      
      <!--====== APPIE FOOTER PART ENDS ======-->
  
  
      <!--====== APPIE BACK TO TOP PART ENDS ======-->
      <div class="back-to-top back-to-top-3">
          <a href="#"><i class="fal fa-arrow-up"></i></a>
      </div>
      <!--====== APPIE BACK TO TOP PART ENDS ======-->
    </div>
  </template> 
  
  <script>
  import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
  import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
  import HeaderProduct from '../Header/HeaderProduct.vue'
  import TitleArea from './TitleArea.vue'
  import Response from './Response.vue'
  import GetStarted from '../Footer/GetStarted.vue'
  
export default {
    components: { SidebarHomeTwo, TitleArea, Response, GetStarted, HeaderProduct, FooterHomeTwo },
    data(){
        return{
            sidebar:false,
        navs:[
            {
                name:'home',                    
                path:'/',                    
            },
            {
                name:'About us',
                path:'/about-us'            
            },
            {
                name:'Zoho',
                path:'/zoho'            
            },
            {
                name:'Industries',
                    childrens:[
                    {
                        name:'Retail and E-Commerce',
                        path:'/product/e-commerce'
                    },
                    {
                        name:'Financial Services',
                        path:'/product/financial-services'
                    },
                    {
                        name:'Online Movie Ticket Booking',
                        path:'/product/online-ticket-booking'
                    },
                    /*{ 
                        name:'News Media',
                        path:''
                    },
                    { 
                        name:'Static Website',
                        path:''
                    },
                    { 
                        name:'Custom Web Application',
                        path:''
                    },
                    { 
                        name:'Travel and Hospitality',
                        path:''
                    },
                    { 
                        name:'Real Estate / Property Builders',
                        path:''
                    },
                    { 
                        name:'Hospitals and Clinics',
                        path:''
                    },*/
                ],
            },
            // {
            //     name:'Services',
            //     path:'/services'
            // },
            // {
            //     name:'Pricing',
            //     path:'/pricing'
            // },
            {
                name:'Career',
                path:'/career'
            },
            {
                name:'contact',
                path:'/contact-us'
            }
        ],
        }
    },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
            this.sidebar = !this.sidebar
        },   
    }
  
  }
  </script>