<template>
  <privacy-policy/>
</template>

<script>
import PrivacyPolicy from '../components/PolicyPages/PrivacyPolicy.vue'
export default {
	components: {PrivacyPolicy },
    metaInfo: {
        title: 'PrivacyPolicy',
        titleTemplate: '%s | Roftr Website development company, Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link:[
            { rel:'alternate', href:'www.roftr.com', hreflang:'en-in'}
        ]
    }
}
</script>

<style>

</style>