<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
export default {
      mounted() {
        let tagmanager = document.createElement('script')
        tagmanager.async = true
        tagmanager.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-57301334-1')
        document.head.appendChild(tagmanager);

        let data = document.createElement('script')
        let inlineScript = document.createTextNode("window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-57301334-1');")
        data.appendChild(inlineScript)
        document.head.appendChild(data);
        
        let schema = document.createElement('script')
        schema.setAttribute('data-schema', 'Organization')
        schema.setAttribute('type', 'application/ld+json')
        let schemaScript = document.createTextNode('{"@context": "http://schema.org","@type": "Organization","name": "Roftr","url":"https://www.roftr.com/","image": "https://www.roftr.com/logo-roftr.png",'+
        '"address": {"@type": "PostalAddress","streetAddress": "1-B, Renga Complex, Peelamedu","addressLocality": "Coimbatore","addressRegion": "Tamil Nadu",'+
        '"postalCode": "641004","Telephone": "+91-422-439-8003"},"sameAs" : ["https://www.facebook.com/roftrindia/","https://twitter.com/roftr"],'+
        '"logo": {"@context": "https://schema.org","@type": "ImageObject","name": "News Bricks","url": "https://www.roftr.com/logo-roftr.png","width": 120,"height": 30} }')
        schema.appendChild(schemaScript)
        document.body.appendChild(schema);
      },
}
</script>
