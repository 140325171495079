<template>
 <div class="service-details-sidebar mr-50">
                        <div class="service-category-widget">
                            <h4>Our Policies</h4>
                        </div>
                        
                        <div class="service-download-widget">
                            <router-link to="/privacy-policy"><i class="fal fa-file-pdf"></i> Privacy Policy</router-link>
                        </div>
                        <div class="service-download-widget">
                            <router-link to="/terms-and-conditions"><i class="fal fa-file-pdf"></i> Terms &amp; Conditions</router-link>
                        </div>
                        <div class="service-download-widget">
                            <router-link to="/disclaimer"><i class="fal fa-file-pdf"></i> Disclaimer</router-link>
                        </div>
                        
                    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>