<template>
  
      <div class="appie-how-it-work-area pt-10 pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="how-it-work-thumb text-center">
                        <img src="@/assets/images/how-it-work-thumb.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-how-it-work-content">
                        <h2 class="title">How to get started</h2>
                        <p>We work with simple requirements to proceed with a new theatre. Basic requirements are Business/Current bank account to Receive Online Payments. Single pc with minimum 20inch monitor, Thermal Printer, and an internet connection to move forward.</p>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="how-it-work-box">
                                    <span>1</span>
                                    <h5 class="title">Seating Layout</h5>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="how-it-work-box">
                                    <span>2</span>
                                    <h5 class="title">Ticket Class &amp; Pricing</h5>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="how-it-work-box">
                                    <span>3</span>
                                    <h5 class="title">Payment Gateway</h5>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="how-it-work-box">
                                    <span>4</span>
                                    <h5 class="title">Start selling in 4 Days.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>