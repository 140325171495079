<template>
   <div>
    <div v-if="showVideo">
        <div class="mfp-bg mfp-ready" @click.prevent="openVideo"></div>
        <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style="overflow: hidden auto;">
            <div class="mfp-container mfp-s-ready mfp-iframe-holder">
                <div class="mfp-content">
                    <div class="mfp-iframe-scaler">
                        <button title="Close (Esc)" type="button" class="mfp-close" @click.prevent="openVideo">×</button>
                        <iframe class="mfp-iframe" src="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1" frameborder="0" allowfullscreen=""></iframe>
                        </div>
                    </div>
                <div class="mfp-preloader">Loading...</div>
            </div>
        </div>
    </div> 
    <div class="appie-faq-8-area pt-100 pb-100" id="mycounter">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="appie-section-title">
                        <h3 class="appie-title">Why Choose Roftr.</h3>
                        <p>Our professional software engineers are solely committed to the business and dedicated towards the project they are working in. Our organizational hierarchy helps us to achieve the target in short span with great effect.</p>
                    </div>
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp wow fadeIn faq-accrodion" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            
                            <div class="accrodion" @click.prevent="OpenQuestion(1)" :class="[showQuestion === 1?'active':'']">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Quality in everything we do</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 1?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>The main reason that you can trust on us is we are customer focused. Because we believe that satisfying your customer is the key to your success and if you succeed, so will we. We at Roftr take time with each client to carefully design and develop the QUALITY solution as per your requirement. We start by identifying clear goals and objectives of the clients before developing a solution.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion" @click.prevent="OpenQuestion(2)" :class="[showQuestion === 2?'active':'']">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Promise in our commitment</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 2?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>We can affirm you that you will get our best, no matter what your budget. It means you’ll always be able to reach someone who is working on your project through the phone or email. We will never sell you technology that you don’t need. We are here to cater your need and not to impose what we have in our stores for you. And it means we do what we promise in our commitment.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion" @click.prevent="OpenQuestion(3)" :class="[showQuestion === 3?'active':'']">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Long-term Maintenance and Support.</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 3?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>We take pride in offering comprehensive and quality services to our clients at very competitive rates, we don’t stop once after developing the solution, we are pleased to offer up gradation to the solution that we offered to you. We like to constantly in touch with you to offer you the best thing around. We are dedicated to the business what we are doing.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="faq-play-box">
            <div class="play-btn">
                <!--<a @click.prevent="openVideo" class="appie-video-popup"><i class="fas fa-play"></i></a>-->
            </div>
        </div>
    </div>
   </div>

</template>

<script>
export default {
    data(){
        return{
            showQuestion:1,
            showVideo:false,
            showCounters: false,
            counterPositions:null,
        }
    },
    mounted() {
        var rec = document.getElementById('mycounter')
        var currentPosition = rec.offsetTop - document.body.scrollTop;
        this.counterPositions=currentPosition
        window.addEventListener('scroll',()=>{
            var currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if(this.counterPositions !== null){
                if(currentScrollPosition + 500>this.counterPositions){
                    this.examplem()  
                }
            }
            
        })
    },
    methods: {
        examplem(){
        this.showCounters = false;
        this.counterPositions=null
      },
        OpenQuestion(value){
            this.showQuestion = value
        },
        openVideo(){
            this.showVideo = !this.showVideo
        },
    }   

}
</script>

<style>

</style>