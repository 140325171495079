<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar" />

    <!--====== OFFCANVAS MENU PART ENDS  ======-->
   
    <!--====== PART START ======-->
    <header-home-two @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    
    <!--====== PART ENDS ======-->

    <!--====== PART ENDS ======-->

    <div class="appie-error-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="appie-error-content text-center">
                        <img src="@/assets/images/error.png" alt="">
                        <span>Sorry!</span>
                        <h3 class="title">The page can’t be found.</h3>
                        <p>The page you're looking for isn't available. Try with another page or use the go home button below</p>
                        <router-link to="/">Back to Home <i class="fal fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    <!--====== PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->
    
    <footer-home-two class="mt-160"/>
    
    <!--====== APPIE FOOTER PART ENDS ======-->


    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->


  </div>
</template>

<script>

import HeaderHomeTwo from './HomeTwo/HeaderHomeTwo.vue'
import SidebarHomeTwo from './Sidebar/SidebarHomeTwo.vue'
import FooterHomeTwo from './Footer/FooterHomeTwo.vue'

export default {
	components: { HeaderHomeTwo, SidebarHomeTwo, FooterHomeTwo },
     data(){
        return{
            sidebar:false,
            
            navs:[
            {
                name:'home',
                path:'/'
            },
            {
                name:'About us',
                path:'/about-us'
            },
            {
                name:'Products',
                    childrens:[
                    {
                        name:'E-commerce Applications',
                        path:'/product/e-commerce'
                    },
                    {
                        name:'Financial Services',
                        path:'/product/financial-services'
                    },
                    {
                        name:'Online Ticket Booking',
                        path:'/product/online-ticket-booking'
                    },
                ],
            },
            /*{
                name:'services',
                path:'/services'
            },*/
            {
                name:'Career',
                path:'/career'
            },
            {
                name:'contact',
                path:'/contact-us'
            },
            {
                name:'Error',
                path:'/error'
            }
            ]
        }
    },
     mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
         toggleSidebar(){
           this.sidebar = !this.sidebar
        },  
    }

}
</script>

<style>


</style>