<template>
      <!--====== APPIE FOOTER PART START ======-->
    
    <section class="appie-footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-about-widget">
                        <div class="logo">
                           
                            <router-link to="/"><img src="@/assets/images/logo-color.png" alt="Roftr"/></router-link>
                        </div>
                        <p>Roftr Clouds LLP is one among the Website and Software development company in Coimbatore, helps you get quality and long-term business solution provider in IT.</p>
                         <router-link to="/about-us">Read More <i class="fal fa-arrow-right"></i></router-link>
                        <div class="social mt-30">
                            <ul>
                                <li><a href="https://www.facebook.com/roftrindia/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://twitter.com/roftr" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <!--<li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">Company</h4>
                        <ul>
                            <li><router-link to="/about-us">About Us</router-link></li>
                            <!-- <li><router-link to="/services">Services</router-link></li> -->
                            <li><router-link to="/contact-us">Contact us</router-link></li>
                            <li><router-link to="/career">Career</router-link></li>
                            <li><router-link to="/terms-and-conditions">Terms and Conditions</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/disclaimer">Disclaimer</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">Products</h4>
                        <ul>
                            <li><router-link to="/product/E-Commerce">E-Commerce Applications</router-link></li>
                            <li><router-link to="/product/financial-services">Financial Services</router-link></li>
                            <li><router-link to="/product/online-ticket-booking">Online Ticket Booking</router-link></li>
                        </ul>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget-info">
                        <h4 class="title">Get In Touch</h4>
                        <ul>
                        <!--
                            <li>
                                <a href="#">
                                    <i class="fal fa-envelope mt-2"></i> 
                                    support@roftr.com
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-mobile mt-2"></i> 
                                    (+91) 9790403333
                                </a>
                            </li> 
                            -->
                            <li>
                                <a href="#">
                                    <i class="fal fa-map-marker-alt mt-2"></i> 
                                    Roftr Clouds LLP <br />A-44, Renga Complex, <br>HUDCO Colony,<br> Near PSG Hospital,<br>Peelamedu, Coimbatore 641004
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-copyright d-flex align-items-center justify-content-between pt-35">
                        <div class="apps-download-btn">
                        </div>
                        <div class="copyright-text">
                            <p>Copyright © {{currentYear}} Roftr Clouds LLP. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>

<style>

</style>