<template>
      <!--====== APPIE SERRVICE 2 PART START ======-->
    
    <section class="appie-services-2-area pb-100" id="service">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="title text-center">
                    <span>We help you Grow...</span>
                        <h1 class="title">{{title}}</h1>
                        <p>{{description}}</p>
                    </div>
                </div>
              
                <!--
                <div class="col-lg-6 col-md-4">
                    <div class="appie-section-title text-right">
                        <a class="main-btn" href="#">View all Features <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div> 
                -->
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fas fa-bolt"></i>
                        </div>
                        <h4 class="title">Zoho Services</h4>
                        <p>Expertise in Zoho products customization and implementations, any thing which helps you grow with advanced Zoho Applications...</p>
                        <router-link to="/zoho">Learn More <i class="fal fa-arrow-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-home"></i>
                        </div>
                        <h4 class="title">CMS Web Application  </h4>
                        <p>Best customized Web app for your business with backend control...</p>
                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-link"></i>
                        </div>
                        <h4 class="title">Online E-Commerce Store</h4>
                        <p>Like to sell online? We have wide range of E-Commerce web applications for all level business people, including rentals...</p>
                        <router-link to="/product/e-commerce">Learn More <i class="fal fa-arrow-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fas fa-bell"></i>
                        </div>
                        <h4 class="title">Movie Ticket Booking Software</h4>
                        <p>Theatre Owner? We have excellent application invested by Theatre owner, dedicate to all theatre owners.</p>
                        <router-link to="/product/online-ticket-booking">Learn More <i class="fal fa-arrow-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-cog"></i>
                        </div>
                        <h4 class="title">Financial Service Application</h4>
                        <p>Suitable for Micro Finance Loan, Personal Loan, Small Business Loan, Business Loan with more features and loan tracking. </p>
                        <router-link to="/product/financial-services">Learn More <i class="fal fa-arrow-right"></i></router-link>
                    </div>
                </div>
                <!--
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-cog"></i>
                        </div>
                        <h4 class="title">Online Hotel Reservation</h4>
                        <p>Own a commission free User-friendly Front desk with Online reservation for your Hotel/Resort.</p>
                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                -->
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-lock"></i>
                        </div>
                        <h4 class="title">Real Estate / Property Builders</h4>
                        <p>Builder? Realestate Agent? We have user-friendly back-end to Add/Edit/delete properties you own. </p>
                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    
    <!--====== APPIE SERRVICE 2 PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        }
    }

}
</script>

<style>

</style>