<template>
    <UserAborted/>
</template>

<script>
import UserAborted from '../components/UserAborted/index'
export default {
	components: {UserAborted },
}
</script>

<style>

</style>