<template>
      <!--====== APPIE FEATURES 2 PART START  ======-->
    
    <section class="appie-features-area-2 appie-features-area-5 pt-90 pb-100" id="features">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">We Offer Simple E-commerce <br/> to Advanced E-commerce Applications</h3>
                        <p>Do you have budget plan for your Online shopping website? We offer best platform as per your budget. </p>
                    </div>
                </div>
            </div>
            <div class="row mt-30 align-items-center">
                <div class="col-lg-6">
                    <div class="appie-features-boxes ">
                        <div class="appie-features-box-item appie-features-box-5-item">
                            <h4 class="title">Rental E-commerce</h4>
                            <p>Suitable for startup companies to evaluate their online sales and move to advanced eCom.</p>
                        </div>
                        <div class="appie-features-box-item item-2 appie-features-box-5-item">
                            <h4 class="title">Regular E-commerce</h4>
                            <p>User friendly customized Online shopping as per your needs with all feature.</p>
                        </div>
                        <div class="appie-features-box-item item-3 appie-features-box-5-item">
                            <h4 class="title">Advanced E-commerce</h4>
                            <p>Built with world leading technology React/Vue js/Nuxt/Laravel  Framework, fast and automated.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-features-thumb wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <img src="@/assets/images/ecommerce-product-banner.png" alt="ecommerce product banner" />
                    </div>
                </div>
            </div>
        </div>
        <div class="features-shape-1">
            <img src="@/assets/images/shape/shape-15.png" alt="shape"/>
        </div>
        <div class="features-shape-2">
            <img src="@/assets/images/shape/shape-14.png" alt="shape"/>
        </div>
        <div class="features-shape-3">
            <img src="@/assets/images/shape/shape-13.png" alt="shape"/>
        </div>
    </section>
    
    <!--====== APPIE FEATURES 2 PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>