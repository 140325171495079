<template>
    <Careers/>
</template>

<script>
import Careers from '../components/Careers/index'
export default {
	components: {Careers  },
    metaInfo: {
        title: 'Careers',
        titleTemplate: '%s | Jobs at Roftr, Software company Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'Roftr is best knowledge base to develop your coding skills. Job openings for experienced PHP, Laravel, Vue JS, developers.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link: [
            { rel: 'alternate', href: 'www.roftr.com', hreflang: 'en-in'}
        ]
    }

}
</script>

<style>

</style>