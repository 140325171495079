<template>
  <section class="appie-services-2-area pt-90 pb-55" id="service">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-lg-6 col-md-8">
                    <div class="appie-section-title">
                        <h3 class="appie-title">We’re driven by our values</h3>
                     <!-- <p>The app provides design and digital marketing. </p> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-tv"></i>
                        </div>
                        <h4 class="title">Requirement Analysis</h4>
                        <p>We want to know what exactly you would like to develop a application based on your business flow.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-code"></i>
                        </div>
                        <h4 class="title">Design &amp; Development</h4>
                        <p>With thousands of stylish responsie design which suits for your business, we proceed with our coding.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fal fa-user-friends"></i>
                        </div>
                        <h4 class="title">Quality Assurance &amp; Testing</h4>
                        <p>With the help of You and our testing team reports, we can make top quality application.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-mobile"></i>
                        </div>
                        <h4 class="title">Implementation &amp; Deployment</h4>
                        <p>Import your old website data or data from any source to your app, goLive with Cloud server</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-retweet"></i>
                        </div>
                        <h4 class="title">Search Engine Optimization</h4>
                        <p>Every web application should be optimized according to the Search Engines, which helps Online visibility.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fal fa-bell"></i>
                        </div>
                        <h4 class="title">Maintenance &amp; Support</h4>
                        <p>We keep support for any bugs and updates as long as needed with regular Data maintenance.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>