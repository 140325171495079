<template>
    <zoho/>
</template>

<script>
import Zoho from "../components/Zoho/index"
export default {
    components: {
        Zoho
    },
    metaInfo: {
        title: 'Roftr Clouds LLp',
        titleTemplate: '%s | Leading Authorized Zoho partner comapny in Coimbatore. Expertise in ZOHO One, CRM, Desk, Finance, Books customization and Support. ',
        htmlAttrs: {
            lang: 'en-in'
        },    
        meta: [
            { name: 'Description', content: 'We can customize and support for ZOHO One, CRM, Desk, Finance, Books and 3rd pary api integrations with Zoho Modules. ' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link: [
            { rel: 'alternate', href: 'www.roftr.com', hreflang: 'en-in'}
        ],
    }
}
</script>

<style>

</style>