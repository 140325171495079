<template>
  <!--====== APPIE FAQ PART START ======-->
    
    <section class="appie-faq-area pb-95">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">{{title}}</h3>
                        <p>{{description}}</p>
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion" @click.prevent="OpenQuestion(1)" :class="[showQuestion === 1?'active':'']">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Any additional cost in Rental Plan?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 1?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Rental eCom cost is fixed and NO additional/hidden charges. You pay monthly Rs. 6500 as monthly expenses for your online sales, nothing else.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion  " :class="[showQuestion === 2?'active':'']" @click.prevent="OpenQuestion(2)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>What is the purpose of Rental E-Commerce application?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 2?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Rental eCom is best suitable for new startup companies who are not interested in huge investments. The rental plan helps analyse your online sales performance and give a clear idea to proceed eCom application or just quit.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            
                              <div class="accrodion  " :class="[showQuestion === 2?'active':'']" @click.prevent="OpenQuestion(2)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>How can i utilise Retail and E-Commerce application?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 2?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Retail modules is complete package of Retail Purchase, Stocks, Sales, Vendor, Customer and more customized modules with same inventory base to Online.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion " :class="[showQuestion === 3?'active':'']" @click.prevent="OpenQuestion(3)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Switch from Basic to Advanced Rental plan?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 3?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Yes, you can switch from Basic to Advanced rental plan, without getting any disturbance to your existing data.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion" :class="[showQuestion ===4?'active':'']" @click.prevent="OpenQuestion(4)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Connect your retail store data to online sales?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 4?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Yes, you can connect your vendor's retail software data to our online eCom application, which needs API support from your Vendor. </p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion" :class="[showQuestion ===4?'active':'']" @click.prevent="OpenQuestion(4)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Is Retail module available without E-commerce?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 4?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Yes, its available. E-Commerce functionalities can be disabled and enabled when ever required.</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE FAQ PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        }
    },
   data(){
       return {
           showQuestion:1,
       }
   },
    methods: {
        OpenQuestion(value){
            this.showQuestion = value
        }
    }

}
</script>

<style>

</style>