<template>
    <Movietickets/>
</template>

<script>
import Movietickets from '../components/Products/Movie-tickets/index'
export default {
	components: {Movietickets },
    metaInfo: {
        title: 'Online Movie Ticket Booking Software India',
        titleTemplate: '%s | Roftr, Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'Free Box Office Movie Ticket booking Software, Online Movie Ticket Reservation with UPI Payments, Google Pay, PayTM and more.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Entertainment'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link: [
            { rel: 'alternate', href: 'www.roftr.com', hreflang: 'en-in'}
        ]
    }
}
</script>

<style>

</style>