<template>
  <div>
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
      <Title-Area/>
    <section class="appie-service-details-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="container mt-2">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="appie-section-title text-center">
                                <h3 class="">Godaddy Professional Email Plans</h3>
                                <p>Get the lowest price for your business email id with your business name and professionally communicate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 active wow animated fadeInLeft">
                                        <div class="pricig-heading">
                                            <h6>Lite</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>400</span><p>/Yearly</p></div>
                                            <p>Suitable for startup companies</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> 10 GB of email storage</li>
                                                <li><i class="fal fa-check"></i> Calendar, contacts and tasks</li>
                                                <li><i class="fal fa-check"></i> 99.9% guaranteed uptime</li>
                                                <li><i class="fal fa-check"></i> Connect with Mobile Email app</li>
                                                <li><i class="fal fa-check"></i> Easy upgrade to 25GB Plan</li>
                                                <li><i class="fal fa-check"></i> Delete &amp; recreate new email, Free</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="tel:09790403333">Interested? Call</a>
                                            </div>
                                             <div class="pricing-rebon">
                                                <span>Most Popular</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 wow animated fadeInUp">
                                        <div class="pricig-heading">
                                            <h6>Medium</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>650</span><p>/Yearly</p></div>
                                            <p>Assign tasks to your team members.</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> 25 GB of email storage</li>
                                                <li><i class="fal fa-check"></i> Calendar, contacts and tasks</li>
                                                <li><i class="fal fa-check"></i> 99.9% guaranteed uptime</li>
                                                <li><i class="fal fa-check"></i> Connect with Mobile Email app</li>
                                                <li><i class="fal fa-check"></i> Delete &amp; recreate new email, Free</li>
                                            </ul>
                                           <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="tel:09790403333">Interested? Call</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 item-2 wow animated fadeInRight">
                                        <div class="pricig-heading">
                                            <h6>Unlimited</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>1400</span><p>/Yearly</p></div>
                                            <p>Suitable for storage based email.</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> Unlimited Space</li>
                                                <li><i class="fal fa-check"></i> Calendar, contacts and tasks</li>
                                                <li><i class="fal fa-check"></i> 99.9% guaranteed uptime</li>
                                                <li><i class="fal fa-check"></i> Connect with Mobile Email app</li>
                                                <li><i class="fal fa-check"></i> Delete &amp; recreate new email, Free</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="tel:09790403333">Interested? Call</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                       
             </div>
        </div>
    </section>   
    <!-- Godaddy email plan -->   
    
    <!-- Google email plan --> 
    <section class="appie-pricing-2-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                
                <div class="appie-section-title text-center">
                                <h3 class="">Google Workspace Email Plans</h3>
                                <p>Build customer trust by giving everyone in your company a professional email address at your domain, like priya@yourcompany and joe@yourcompany. Also create group mailing lists, such as sales@yourcompany.</p>
                            </div>
                    <div class="appie-section-title text-center">
                        <div class="appie-pricing-tab-btn">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation"  @click.prevent="change_plan">
                                    <a class="nav-link" :class="[switchPlan?'active':'']" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Monthly</a>
                                </li>
                                <li class="nav-item" role="presentation" :class="[switchPlan?'on':'off']"  @click.prevent="change_plan">
                                    <a class="nav-link" :class="[switchPlan === false?'active':'']" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Yearly</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade" :class="[switchPlan?'active show':'']" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 active wow animated fadeInLeft">
                                        <div class="pricig-heading">
                                            <h6>Fresh</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>125</span><p>/month</p></div>
                                            <p>Billed &#8377; 147.5 /user montly, 18% tax included.</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> 30 GB cloud storage per user</li>
                                                <li><i class="fal fa-check"></i> Drive, Meet, Sheets, Docs, and more</li>
                                                <li><i class="fal fa-check"></i> 99.9% guaranteed uptime</li>
                                                <li><i class="fal fa-check"></i> Rules to Monitor Send/Receive Email</li>
                                                <li><i class="fal fa-check"></i> 100 participant video meetings</li>
                                                <li><i class="fal fa-check"></i> Delete &amp; recreate new email, Free</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="tel:09790403333">Interested? Call</a>
                                            </div>
                                            <div class="pricing-rebon">
                                                <span>Most Popular</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 wow animated fadeInUp">
                                        <div class="pricig-heading">
                                            <h6>Sweet</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>672</span><p>/month</p></div>
                                            <p>Billed &#8377; 792.96 /user montly, 18% tax included.</p>
                                        </div>
                                        <div class="pricig-body">
                                           <ul>
                                                <li><i class="fal fa-check"></i> 2 TB cloud storage per user</li>
                                                <li><i class="fal fa-check"></i> Drive, Meet, Sheets, Docs, and more</li>
                                                <li><i class="fal fa-check"></i> 99.9% guaranteed uptime</li>
                                                <li><i class="fal fa-check"></i> Rules to Monitor Send/Receive Email</li>
                                                <li><i class="fal fa-check"></i> 150 participant video meetings + recording</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="tel:09790403333">Interested? Call</a>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 item-2 wow animated fadeInRight">
                                        <div class="pricig-heading">
                                            <h6>Juicy</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>1,260</span><p>/month</p></div> 
                                            <p>Billed &#8377; 1,486.8 /user montly, 18% tax included.</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> 5 TB cloud storage per user</li>
                                                <li><i class="fal fa-check"></i> Drive, Meet, Sheets, Docs, and more</li>
                                                <li><i class="fal fa-check"></i> 99.9% guaranteed uptime</li>
                                                <li><i class="fal fa-check"></i> Rules to Monitor Send/Receive Email</li>
                                                <li><i class="fal fa-check"></i> 500 participant video meetings + recording, attendance tracking</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="tel:09790403333">Interested? Call</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="[switchPlan=== false?'active show':'']"  id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 active animated fadeInLeft">
                                        <div class="pricig-heading">
                                            <h6>Fresh</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>1,500</span><p>/Yearly</p></div>
                                            <p>Billed &#8377; 1,770 /user annually, 18% tax included.</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> 30 GB cloud storage per user</li>
                                                <li><i class="fal fa-check"></i> Drive, Meet, Sheets, Docs, and more</li>
                                                <li><i class="fal fa-check"></i> 99.9% guaranteed uptime</li>
                                                <li><i class="fal fa-check"></i> Rules to Monitor Send/Receive Email</li>
                                                <li><i class="fal fa-check"></i> 100 participant video meetings</li>
                                                <li><i class="fal fa-check"></i> Delete &amp; recreate new email, Free</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="tel:09790403333">Interested? Call</a>
                                            </div>
                                            <div class="pricing-rebon">
                                                <span>Most Popular</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 animated fadeInUp">
                                        <div class="pricig-heading">
                                            <h6>Sweet</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>8,064</span><p>/Yearly</p></div>
                                             <p>Billed &#8377; 9,516 /user annually, 18% tax included.</p>
                                        </div>
                                        <div class="pricig-body">
                                             <ul>
                                                <li><i class="fal fa-check"></i> 2 TB cloud storage per user</li>
                                                <li><i class="fal fa-check"></i> Drive, Meet, Sheets, Docs, and more</li>
                                                <li><i class="fal fa-check"></i> 99.9% guaranteed uptime</li>
                                                <li><i class="fal fa-check"></i> Rules to Monitor Send/Receive Email</li>
                                                <li><i class="fal fa-check"></i> 150 participant video meetings + recording</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="tel:09790403333">Interested? Call</a>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="pricing-one__single pricing-one__single_2 item-2 animated fadeInRight">
                                        <div class="pricig-heading">
                                            <h6>Juicy</h6>
                                            <div class="price-range"><sup>&#8377;</sup> <span>15,120</span><p>/Yearly</p></div>
                                            <p>Billed &#8377; 17,842 /user annually, 18% tax included.</p>
                                        </div>
                                        <div class="pricig-body">
                                            <ul>
                                                <li><i class="fal fa-check"></i> 5 TB cloud storage per user</li>
                                                <li><i class="fal fa-check"></i> Drive, Meet, Sheets, Docs, and more</li>
                                                <li><i class="fal fa-check"></i> 99.9% guaranteed uptime</li>
                                                <li><i class="fal fa-check"></i> Rules to Monitor Send/Receive Email</li>
                                                <li><i class="fal fa-check"></i> 500 participant video meetings + recording, attendance tracking</li>
                                            </ul>
                                            <div class="pricing-btn mt-35">
                                                <a class="main-btn" href="tel:09790403333">Interested? Call</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Google email plan --> 
    
   <get-started/>
   <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    
    <div class="back-to-top back-to-top-3">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>
 
<script>

import SidebarHomeTwo from '../../Sidebar/SidebarHomeTwo.vue'
import FooterHomeTwo from '../../Footer/FooterHomeTwo.vue'
import HeaderProduct from '../../Header/HeaderProduct.vue'
import TitleArea from './TitleArea.vue'
import GetStarted from '../../Footer/GetStarted.vue'



export default {
	components: { SidebarHomeTwo, GetStarted, HeaderProduct, FooterHomeTwo, TitleArea },
        data(){
        return{
            sidebar:false,
            
            navs:[
                {
                    name:'home',                    
                    path:'/',                    
                },
                {
                    name:'About us',
                    path:'/about-us'            
                },
                {
                    name:'Products',
                        childrens:[
                        {
                            name:'E-commerce Applications',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                    ],
                },
               {
                    name:'services',
                    path:'/services'
                },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
            switchPlan:true,
        }
    },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },
        change_plan(){
            this.switchPlan = !this.switchPlan
        },   
    }

}
</script>