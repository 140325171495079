<template>
      <!--====== APPIE ABOUT 3 PART START ======-->
    
    <section class="appie-about-3-area pt-100 pb-100" id="features">
        <div class="container">
        
        <!-- Security and Performance -->
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="appie-about-thumb-3 wow animated fadeInLeft" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <img src="@/assets/images/about-thumb-2.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-traffic-title">
                        <h3 class="title">Static Website Security and Performance.</h3>
                        <p>Not only database oriented website need to care more for security and high performance, even static website needs it.</p>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="appie-traffic-service mb-30">
                                <div class="icon">
                                    <img src="@/assets/images/icon/5.svg" alt="">
                                </div>
                                <h5 class="title">Cloud Server</h5>
                                <p>We host static website in high performance cloud servers, which is simpler, faster and affordable with average 50GB storage.</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="appie-traffic-service item-2 mb-30">
                                <div class="icon">
                                    <img src="@/assets/images/icon/1.png" alt="">
                                </div>
                                <h5 class="title">SSL Certificate</h5>
                                <p>No extra cost for SSL (httpS://) certificates, built-in with cloud server. Recognised by all search engines for best search result.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Security and Performance -->
            
            <!-- Support and Maintenance -->
            
            <div class="row align-items-center mt-100  flex-column-reverse flex-lg-row">
                <div class="col-lg-6">
                    <div class="appie-traffic-title">
                        <h3 class="title">Support and Maintenance</h3>
                        <p>He nicked it tickety boo harry the cras bargy chap mush spiffing spend a penny the full monty burke butty.</p>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="appie-traffic-service mb-30 item-3">
                                <div class="icon">
                                    <img src="@/assets/images/icon/6.svg" alt="">
                                </div>
                                <h5 class="title">Website updates</h5>
                                <p>Mucker plastered bugger all mate morish are.</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="appie-traffic-service item-2 mb-30 item-4">
                                <div class="icon">
                                    <img src="@/assets/images/icon/7.svg" alt="">
                                </div>
                                <h5 class="title">Maintenance</h5>
                                <p>Mucker plastered bugger all mate morish are.</p>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="traffic-btn mt-50">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-about-thumb-3 text-right wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <img src="@/assets/images/about-thumb-3.png" alt="">
                    </div>
                </div>
            </div>
            
             <!-- Support and Maintenance -->
             
              <!-- Yearly Renewal Cost -->
            
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="appie-about-thumb-3 wow animated fadeInLeft" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <img src="@/assets/images/about-thumb-2.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-traffic-title">
                        <h3 class="title">Yearly Renewal Cost.</h3>
                        <p>Not only database oriented website need to care more for security and high performance, even static website needs it.</p>
                    </div>
                     <div class="row">
                        <div class="col-sm-6">
                            <div class="appie-traffic-service mb-30">
                                <div class="icon">
                                    <img src="@/assets/images/icon/5.svg" alt="">
                                </div>
                                <h5 class="title">Server Renewal</h5>
                                <p>We host static website in high performance cloud servers, which is simpler, faster and affordable with average 50GB storage.</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="appie-traffic-service item-2 mb-30">
                                <div class="icon">
                                    <img src="@/assets/images/icon/1.png" alt="">
                                </div>
                                <h5 class="title">Domain Renewal</h5>
                                <p>No extra cost for SSL (httpS://) certificates, built-in with cloud server. Recognised by all search engines for best search result.</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            
             <!-- Yearly Renewal Cost -->
             
              <!-- Yearly Renewal Cost -->
            
            <div class="row align-items-center mt-100  flex-column-reverse flex-lg-row">
                <div class="col-lg-6">
                    <div class="appie-traffic-title">
                        <h3 class="title">Browse over 40k Apps over the world</h3>
                        <p>He nicked it tickety boo harry the cras bargy chap mush spiffing spend a penny the full monty burke butty.</p>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="appie-traffic-service mb-30 item-3">
                                <div class="icon">
                                    <img src="@/assets/images/icon/6.svg" alt="">
                                </div>
                                <h5 class="title">User Interactive</h5>
                                <p>Mucker plastered bugger all mate morish are.</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="appie-traffic-service item-2 mb-30 item-4">
                                <div class="icon">
                                    <img src="@/assets/images/icon/7.svg" alt="">
                                </div>
                                <h5 class="title">Choose a App</h5>
                                <p>Mucker plastered bugger all mate morish are.</p>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="traffic-btn mt-50">
                                <a class="main-btn" href="#">Learn More <i class="fal fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-about-thumb-3 text-right wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <img src="@/assets/images/about-thumb-3.png" alt="">
                    </div>
                </div>
            </div>
            
             <!-- Yearly Renewal Cost -->
             
        </div>
    </section>
   
    
    <!--====== APPIE ABOUT 3 PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>