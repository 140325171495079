<template>
    <ECommerce/>
</template>

<script>
import ECommerce from '../components/Products/E-Commerce/index'
export default {
	components: {ECommerce },
    metaInfo: {
        title: 'E-Commerce',
        titleTemplate: '%s | Rental application available from Rs. 6500 monthly, Roftr ecommerce development company, Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'Rental online shopping website available for startup companies, advanced ecom sites, UPI and QR code payments, Lowest Transaction fees.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link:[
            { rel:'alternate', href:'www.roftr.com', hreflang:'en-in'}
        ]
    }
}
</script>

<style>

</style>